import { get } from 'lodash';
import moment from 'moment';
import { merge } from 'lodash';

import { bookMovers as helpers } from '@moved/product';

import { BaseTask } from '../../components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class BookMovers extends BaseTask {
  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useMoverBookingTask,
        useTaskablePending: selectors.useMoverBookingTaskPending,
        ...selectors,
      }
    });
  }

  static label = 'Book Movers';
  static slug = 'book-movers';
  static icon = 'Loader';
  static lockedMessage = 'Request your move date & time reservation first.';
  static helpers = helpers;

  _flowBuilder(taskData) {
    switch(get(taskData, 'selected_moving_method')) {
      case 'diy':
        // full diy flow
        return [
          this.screens['moving-plan'],
          this.screens['confirmation'],
        ];
      case 'truck-rental':
        // full truck flow
        return [
          this.screens['moving-plan'],
          ...get(taskData,'truck_provider_ads',[]).map(({ad_details,...ad}) =>
            merge({ type: 'truck' }, this.screens['ad-content'], { ad }, ad_details)),
          this.screens['confirmation'],
        ];
      case 'hiring-labor':
        // full labor flow
        return [
          this.screens['moving-plan'],
          ...get(taskData,'labor_provider_ads',[]).map(({ad_details,...ad}) =>
            get(ad,'label') === 'hire-a-helper' ? this.screens['labor-quotes'] : merge({ type: 'labor' }, this.screens['ad-content'], { ad }, ad_details)),
          this.screens['confirmation'],
        ];
      case 'container-rental':
        // full container flow
        return [
          this.screens['moving-plan'],
          ...get(taskData,'container_provider_ads',[]).map(({ad_details,...ad}) =>
            merge({ type: 'container' }, this.screens['ad-content'], { ad }, ad_details)),
          this.screens['confirmation'],
        ];
      case 'professional-movers':
        switch(get(taskData,'interested_in_moved')) {
          case true:
            // unknown geography
            if(get(taskData,'mover_booking.stops',[]).length < 2) return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              !get(taskData,'settings.anonymous_ads_enabled') && this.screens['date'],
              get(taskData,'settings.anonymous_ads_enabled') ?
                this.screens['moving-stops'] :
                this.screens['address'],
              this.screens['mystery'],
              this.screens['confirmation'],
            ].filter(v=>v);

            // full long distance flow
            if(get(taskData,'mover_booking.long_distance')) return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              !get(taskData,'settings.anonymous_ads_enabled') && this.screens['date'],
              get(taskData,'settings.anonymous_ads_enabled') ?
                this.screens['moving-stops'] :
                this.screens['address'],
              get(taskData,'settings.anonymous_ads_enabled') && this.screens['date'],
              this.screens['long-distance'],
              this.isBooked(taskData) ?
                this.screens['confirmation-book-movers'] :
                get(taskData,'completed_at') ?
                  this.screens['confirmation-user-provided'] :
                  this.screens['confirmation-long-distance'],
            ].filter(v=>v);

            // full non-core-geo flow
            if(get(taskData,'mover_booking.eligible_for_rates') === false) return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              !get(taskData,'settings.anonymous_ads_enabled') && this.screens['date'],
              get(taskData,'settings.anonymous_ads_enabled') ?
                this.screens['moving-stops'] :
                this.screens['address'],
              get(taskData,'settings.anonymous_ads_enabled') && this.screens['date'],
              this.screens['confirmation-non-core-geo'],
            ];

            // full local move flow (provide vendor lead)
            if(get(taskData,'settings.anonymous_ads_enabled')) return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              this.screens['moving-stops'],
              this.screens['moving-company-preference'],
              get(taskData,'selected_moving_company_ads.length') ?
                this.screens['confirmation-vendor-lead'] :
                this.screens['confirmation-user-provided'],
            ];

            // full local move flow (hourly rate quotes)
            return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              this.screens['date'],
              this.screens['address'],
              this.screens['move-size'],
              this.screens['special'],
              this.screens['moving-quotes'],
              this.screens['moving-summary'],
              this.screens['agreements'],
              this.screens['moving-payment'],
              this.screens['confirmation-book-movers'],
            ];

          case false:
            // full user-provided flow
            return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              this.screens['moving-companies'],
              this.screens['confirmation-user-provided'],
            ];

          default:
            // partial booking flow
            return [
              this.screens['moving-plan'],
              this.screens['moving-provider'],
              this.screens['mystery'],
              this.screens['confirmation'],
            ];
        }
      default:
        // initial flow
        return [
          this.screens['moving-plan'],
          this.screens['mystery'],
          this.screens['confirmation'],
        ];
    }
  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    // if booked show confirmation screen
    if(this.isBooked(taskData)) return this._flow[this._flow.length-1];
    return super.getRecommendedScreen(taskData);
  }

  canAccessScreen(taskData, slug) {
    // allow skipping to confirmation screen if booked manually (even if skipping required screens)
    if(slug === 'confirmation' && this.isBooked(taskData)) return true;
    return super.canAccessScreen(taskData, slug);
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  isBooked(taskData) {
    return (
      taskData?.mover_booking?.booked_service_order != null &&
      taskData?.mover_booking?.booked_service_order?.order_status !== 'cancelled'
    );
  }

  canBookDate(date) {
    const deadlineToBook = moment(date).subtract(1,'d').set({h:14});
    return moment().isBefore(deadlineToBook);
  }

  isCancellationFree(taskData) {
    const moveDate = get(taskData,'mover_booking.requested_pickup_date');
    const lastCancellationDate = moveDate ? moment(moveDate).subtract(3,'days') : false;
    return lastCancellationDate ? moment().isSameOrBefore(lastCancellationDate,'day') : false;
  }
  /* END TASK SPECIFIC */

}
